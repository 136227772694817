import React, { useEffect, useMemo, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';

import { getAppointmentOptions } from 'Src/utils/availability';
import { shouldRenderDirectBookInDrawer } from 'Common/config';
import ProfileCtaModal from '../profile-cta-modal';
import { NEW_PATIENT, ESTABLISHED_PATIENT } from '../../utils/constants';
import { pageViewEvent } from '../../tracking/tracking-utils';
import { pages } from '../../tracking/constants';
import {
  isApptInfoValidAndComplete,
  shouldApplyDefaultAppointmentInfo,
  purposesExist
} from '../lib/utils';

export const useTracking = ({
  log,
  config,
  providerId,
  profile,
  slotsByProviderId,
  error,
  loading
}) => {
  const hasSlotsInState = slotsByProviderId && slotsByProviderId[providerId];

  useEffect(() => {
    if (hasSlotsInState && config.display_availability_in_search) {
      const { slots = [], apptInfo: { purpose, relationship } = {} } =
        slotsByProviderId[providerId];

      if (slots.length) {
        // profile page only displays avail-tiles if slots are returned (no unavailable view on profile)
        // therefore, only log if slots are returned
        log('user_action.profile.availability_tiles_displayed', {
          provider_id: providerId,
          slots: slots.length,
          purpose,
          relationship
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSlotsInState, config.display_availability_in_search]);

  useEffect(() => {
    /** We log a page view event when all of the following are satisfied: the provider data exists, there is no error, and we are not loading */
    if (profile && !error && !loading) {
      log(pageViewEvent(pages.PROVIDER_PROFILE));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, profile, loading]);
};

export const useProfileCtaModal = ({
  profile,
  bookingLinkTarget,
  directBookHref,
  isVirtualCare,
  log,
  config
}) => {
  const [profileCtaModalIsOpen, setProfileCtaModalIsOpen] = useState(false);
  const profileCtaModal = profile ? (
    <ProfileCtaModal
      isOpen={profileCtaModalIsOpen}
      onClose={() => setProfileCtaModalIsOpen(false)}
      provider={profile?.provider}
      bookingLinkTarget={bookingLinkTarget}
      directBookHref={directBookHref}
      isVirtualCare={isVirtualCare}
      log={log}
      config={config}
    />
  ) : null;

  return { profileCtaModal, setProfileCtaModalIsOpen };
};

const DEFAULT_PATIENT_RELATIONSHIP_OPTIONS = {
  [NEW_PATIENT]: [],
  [ESTABLISHED_PATIENT]: []
};

export const useProfileAvailability = ({
  availabilityError,
  config,
  fetchPurposesByProviderId,
  fetchSlots,
  history,
  location,
  log,
  profile,
  providerId,
  purpose,
  purposeOptionsByProviderId,
  relationship,
  slots
}) => {
  const [apptInfo, setApptInfo] = useState({
    relationship,
    purpose
  });
  const purposes = purposeOptionsByProviderId
    ? purposeOptionsByProviderId[providerId]
    : null;

  const appointmentOptions = useMemo(() => {
    if (profile == null || purposesExist(purposes) === false) return;

    const appointmentInfo = { relationship, purpose };
    return getAppointmentOptions(
      config,
      profile.provider,
      appointmentInfo,
      purposes
    );
  }, [relationship, purpose, config, profile, purposes]);

  useEffect(() => {
    if (purposes == null) {
      // fetch purposes if they are not already in the store
      fetchPurposesByProviderId(providerId);

      // fetch initial slots if they are not already in the store
      if (isApptInfoValidAndComplete(apptInfo)) {
        fetchSlots(apptInfo, [providerId]);
      }
    }

    // we won't modify apptInfo if it is already set
    if (
      profile &&
      shouldApplyDefaultAppointmentInfo(config, { apptInfo, purposes, profile })
    ) {
      if (appointmentOptions.initialAppointmentInfo) {
        setApptInfo(appointmentOptions.initialAppointmentInfo);
      }
      if (
        isApptInfoValidAndComplete(appointmentOptions.initialAppointmentInfo)
      ) {
        fetchSlots(appointmentOptions.initialAppointmentInfo, [providerId]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, providerId, purposes, appointmentOptions]);

  const patientRelOptions =
    appointmentOptions == null
      ? DEFAULT_PATIENT_RELATIONSHIP_OPTIONS
      : appointmentOptions.purposeOptionsByRelationship;

  const availabilityByLocationId = useMemo(
    () =>
      (slots || []).reduce((acc, slot) => {
        if (acc[slot.location_id]) {
          return {
            ...acc,
            [slot.location_id]: [...acc[slot.location_id], slot]
          };
        }

        return {
          ...acc,
          [slot.location_id]: [slot]
        };
      }, {}),
    [slots]
  );

  const handleTimeSlotsClick = (date, time, provider_id, href) => {
    log('user_action.provider_timeslot_selected_provider_profile', {
      date,
      time,
      provider_id
    });
    const provider = profile.provider;
    if (shouldRenderDirectBookInDrawer(config)) {
      provider &&
        history.push(href, {
          provider: {
            location,
            provider
          }
        });
    }
  };

  const handleViewMoreClick = (_, href) => {
    log(
      'user_action.search_results.profile.availability_tiles.view_all_appointments'
    );
    if (shouldRenderDirectBookInDrawer(config)) {
      const provider = profile.provider;
      provider &&
        history.push(href, {
          provider: {
            location,
            provider
          }
        });
    }
  };

  const editApptInfoCallback = (newApptInfo) => {
    setApptInfo(newApptInfo);
    fetchSlots(newApptInfo, [providerId]);
  };

  const availabilityErrorIsEmpty = _isEmpty(availabilityError);
  const bookingInfoLoading = availabilityErrorIsEmpty && purposes == null;

  return {
    apptInfo,
    availabilityByLocationId,
    handleTimeSlotsClick,
    handleViewMoreClick,
    editApptInfoCallback,
    patientRelOptions,
    bookingInfoLoading
  };
};
